import { Row, Col, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";

const CallRequestHold = (props) => {
  const { callRequestType, source} = props;

  let holdForschema = yup.object().shape({
    requestHoldFor: yup
      .string()
      .required("Please select request hold to option."),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      requestHoldFor: "",
    },
    validationSchema: holdForschema,
    onSubmit: (values) => {
      console.log("This is selected Value ", values);
      if(source === 'creditqDefaulters'){
        callRequestType("withoutServiceCharge");
      }else{
        callRequestType(values.requestHoldFor);
      }
      
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row>
        {source !== 'creditqDefaulters' && (<Col md={6}>
          <input
            className="form-check-input"
            type="radio"
            name="requestHoldFor"
            id="AdvanceServiceCharge"
            onChange={formik.handleChange}
            value="AdvanceServiceCharge"
          />
          <label
            className="form-check-label ps-2"
            htmlFor="AdvanceServiceCharge"
          >
            Advance Service Charge
          </label>
        </Col>)}
        <Col md={6}>
          <input
            className="form-check-input"
            type="radio"
            name="requestHoldFor"
            id="WithoutServiceCharge"
            onChange={formik.handleChange}
            value="WithoutServiceCharge"
          />
          <label
            className="form-check-label ps-2"
            htmlFor="WithoutServiceCharge"
          >
            Without Service Charge
          </label>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="px-2 py-2">
          {/* Use 'requestHoldFor' instead of 'h' */}
          {formik.touched.requestHoldFor && formik.errors.requestHoldFor ? (
            <span className="error text-danger">
              {formik.errors.requestHoldFor}
            </span>
          ) : null}
        </Col>
        <Col className="mt-3">
          <button className="btn btn-small btn-theme btn btn-primary" type="submit">
            Process
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default CallRequestHold;
