//import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound";
import RequireAuth from "../components/RequireAuth";
//const Login = lazy(() => import("../pages/auth/Login"));
import Login from "../pages/auth/Login";
//const Home = lazy(() => import("../pages/Home"));
import Home from "../pages/Home";
import Defaulters from "../pages/Defaulters";
import Defaulter from "../pages/Defaulter";
import CreditqDefaulter from "../pages/Creditq-Defaulter/CreditqDefaulter";
import SettlementRequests from "../pages/SettlementRequests";
import PaymentCreate from "../pages/Payment/Create";
import PaymentEdit from "../pages/Payment/Edit";
import CallRequestCreate from "../pages/CallRequestHold/create";
import CallRequestEdit from "../pages/CallRequestHold/Edit";
import DefaulterContacts from "../pages/Defaulter-contacts/DefaulterContacts";
import { getPermission } from "../store/slices/defaulter";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage } from "../store/slices/message";
import { useEffect } from "react";
import { checkPermission } from "../helpers/common";
import CreditqDefaulters from "../pages/Creditq-Defaulters/CreditqDefaulters";
import CQPaymentCreate from "../pages/CreditQ/Payments/create";

export function RenderRoutes({ routes }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getPermission());
  }, []);
  const { permission } = useSelector((state) => state.defaulterSlice);
  return (
    <Routes>
      <Route
        key={"ROOT"}
        path={"/"}
        exact={true}
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route key={"LOGIN"} path={"/login"} exact={true} element={<Login />} />
      <Route
        key={"APP"}
        path={"/app"}
        exact={false}
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        key={"DEFAULTERS"}
        path={"/defaulters"}
        exact={true}
        element={
          <RequireAuth>
            <Defaulters />
          </RequireAuth>
        }
      />
      {checkPermission(
        permission?.data,
        "Verify Defaulter Contact",
        "listView"
      ) === 1 && (
        <Route
          key={"DEFAULTERS"}
          path={"/defaulters-contacts"}
          exact={true}
          element={
            <RequireAuth>
              <DefaulterContacts />
            </RequireAuth>
          }
        />
      )}
      {checkPermission(
        permission?.data,
        "CreditQ Defaulters Page",
        "listView"
      ) === 1 && (
        <Route
          key={"CREDITQ DEFAULTERS"}
          path={"/creditq-defaulters"}
          exact={true}
          element={
            <RequireAuth>
              <CreditqDefaulters />
            </RequireAuth>
          }
        />
      )}
      <Route
        key={"DEFAULTER"}
        path={"/defaulter/:id"}
        exact={true}
        element={
          <RequireAuth>
            <Defaulter />
          </RequireAuth>
        }
      />

      <Route
        key={"SETTLEMENTREQUESTS"}
        path={"/settlement/requests"}
        exact={true}
        element={
          <RequireAuth>
            <SettlementRequests />
          </RequireAuth>
        }
      />
      <Route
        key={"PAYMENTCREATE"}
        path={"/payment/:paid_to/:defaulter_id/create"}
        exact={true}
        element={
          <RequireAuth>
            <PaymentCreate />
          </RequireAuth>
        }
      />
      <Route
        key={"PAYMENTEDITVIEW"}
        path={"/payment/:paid_to/:defaulter_id/edit/:payment_id"}
        exact={true}
        element={
          <RequireAuth>
            <PaymentEdit />
          </RequireAuth>
        }
      />
      <Route
        key={"CALLHOLDCREATE"}
        path={"/callHold/:holdType/:defaulter_id/create/:source"}
        exact={true}
        element={
          <RequireAuth>
            <CallRequestCreate />
          </RequireAuth>
        }
      />

      <Route
        key={"CALLHOLDEDITVIEW"}
        path={
          "/holdRequest/:holdType/:defaulter_case_id/edit/:callRequestId/:source"
        }
        exact={true}
        element={
          <RequireAuth>
            <CallRequestEdit />
          </RequireAuth>
        }
      />
      <Route
        key={"CREDITQ-DEFAULTER"}
        path={"/creditq-defaulters/:id"}
        exact={true}
        element={
          <RequireAuth>
            <CreditqDefaulter />
          </RequireAuth>
        }
      />
      <Route
        key={"PAYMENTTYPE"}
        path={"creditq/defaulter/payment/:type/:defaulter_id/create"}
        exact={true}
        element={
          <RequireAuth>
            <CQPaymentCreate />
          </RequireAuth>
        }
      />
      <Route exact={true} element={<NotFound />} path="*" />
    </Routes>
  );
}
