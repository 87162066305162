import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import CreditqDefaulters from "../../../services/creditqDefaulters/index";

export const getDefaulters = createAsyncThunk(
  "get/creditq/defaulters",
  async ({ page, filterData }, thunkAPI) => {
    try {
      const result = await CreditqDefaulters.getDefaulters(page, filterData);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getDefaulter = createAsyncThunk(
  "get/creditq-defaulter",
  async ({ id, filterData }, thunkAPI) => {
    try {
      const result = await CreditqDefaulters.getDefaulter(id, filterData);

      console.log("result: ", result.data);
      if (result.status === 200) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = { creditqDefaulters: {}, creditqDefaulter: {} };

const creditqDefaultersSlice = createSlice({
  name: "Creditq-Defaulters",
  initialState,

  extraReducers: {
    [getDefaulters.fulfilled]: (state, action) => {
      console.log("Creditq Defaulters fulfilled", action);
      state.creditqDefaulters = action.payload;
    },
    [getDefaulters.rejected]: (state, action) => {
      state.creditqDefaulters = null;
    },
    [getDefaulter.fulfilled]: (state, action) => {
      console.log("Creditq-Defaulter fulfilled with payload:", action.payload);
      state.creditqDefaulter = action.payload;
    },
    [getDefaulter.rejected]: (state) => {
      state.creditqDefaulter = null;
    },
  },
});

export default creditqDefaultersSlice.reducer;
