import React, { useState, useEffect } from "react";
import Layout from "../../layouts/default";
import { clearMessage } from "../../store/slices/message";
import { getDefaulters } from "../../store/slices/defaulters";
import { useSelector, useDispatch } from "react-redux";
import PaginationList from "../../components/Pagination";
import TableList from "../../components/TableList";
import FilterForm from "./Filter";
import { mergeFields } from "../../helpers/DataTable";
import { currencyFormatter } from "../../helpers/common";

export const Defaulters = () => {
  console.log("Defaulters");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  //const [itempage, setPage] = useState(1);
  const defaultFilterData = {
    defaulter_gstn: "DoNotShowList",
  };
  const [filterData, setFilterData] = useState(defaultFilterData);

  const { message } = useSelector((state) => state.messageSlice);
  const { defaulters } = useSelector((state) => state.defaultersSlice);

  const dispatch = useDispatch();

  useEffect(() => {
    if (filterData) dispatch(getDefaulters({ page, filterData }));
    setLoading(false);
  }, [dispatch, setLoading, page, filterData]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  //Heading of the table
  const thead = [
    "#",
    "Case ID",
    "Defaulter",
    "Supplier",
    "Amount(Rs.)",
    "Status",
    "Action",
  ];

  let defaultersData = [];
  let tdata = [];
  let paginationData = {};
  if (defaulters) {
    defaultersData = defaulters.data.defaulters;
    paginationData = defaulters.pagination;
  }

  const dbFieldsForTd = [
    { field: "id", label: "", prefix: "", postfix: "" },
    {
      fields: ["buyer_trade_name", "buyer_gstn", "buyer_mobile"],
      callback: mergeFields,
      labels: ["Buyer Name", "GSTN", "Mobile"],
    },
    {
      fields: ["supplier_trade_name", "supplier_gstn", "supplier_mobile"],
      callback: mergeFields,
    },
    { field: "amount", callback: currencyFormatterList },
    { field: "defaulters_status" },
  ];

  const tableActions = [
    {
      page: "Defaulter",
      icon: "",
      label: "View",
      iconOnly: true,
      link: "/defaulter/:id",
      linkVars: ["id"],
      onClick: false, //pass a function to handle the action
      //any other prop which require with Link
    },
  ];

  return (
    <Layout heading="Defaulters">
      <FilterForm
        setFilterData={setFilterData}
        setLoading={setLoading}
        defaultFilterData={defaultFilterData}
      />
      <br />
      {message && <div className="alert alert-success">{message}</div>}
      <TableList
        thead={thead}
        tdata={tdata}
        fullData={defaultersData}
        dbFieldsForTd={dbFieldsForTd}
        loading={loading}
        tableActions={tableActions}
        paginationData={paginationData}
      />

      {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
    </Layout>
  );
};

const currencyFormatterList = (field, defaulter) => {
  return (
    <>
      <div>{currencyFormatter(defaulter[field.field])}</div>
    </>
  );
};
