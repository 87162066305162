import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import Classes from "./style.module.css";

export const SettlementPaymentList = (props) => {
  const { payments, defaulter_case_id } = props;

  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>Amount</th>
          <th>total Payable Amount</th>
          <th>Settlement Status</th>
          <th>Name</th>
          <th>Paid To</th>
          <th>Created</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
    );
  };

  const _itemsList = () => {
    if (!payments?.data?.response.length) {
      return (
        <tr>
          <td colSpan="8">No Settlement Charge found!!</td>
        </tr>
      );
    }
    console.log("################payment", payments);
    return payments.data.response.map((item, index) => {
      console.log("service charge item list", item);
      console.log("################item", item);
      return (
        <tr key={item.id}>
          <td>{item.settlement_amount}</td>
          <td>{item.total_payable_amount}</td>
          <td>{item.settlement_status.replace("_", " ")}</td>
          <td>{item.name}</td>
          <td>{item.paid_to}</td>
          <td>{format(new Date(item.date_created), "yyyy-MM-dd")}</td>
          <td>{item.status}</td>
          <td className={Classes.actions}>
            <Link
              to={`/payment/${item.paid_to}/${defaulter_case_id}/edit/${item.id}`}
            >
              {item.status === "draft" || item.status === "pending_for_approval"
                ? "Edit"
                : "View"}
            </Link>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Table className="striped bordered  hover">
        {_tableHeader()}
        <tbody>{_itemsList()}</tbody>
      </Table>
    </>
  );
};
export default SettlementPaymentList;
