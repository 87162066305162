import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import CreditqDefaulters from "../services/creditqDefaulters/index";
import { getToken } from "../helpers/common";

export const SettlementPaymentHistory = (props) => {
  const { payments, defaulter_id } = props;

  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>Payment Received Date</th>
          <th>Settlement entry date</th>
          <th>Settlement amount</th>
          <th>Settlement Type</th>
          <th>Status</th>
          <th>Evidence</th>
          {/* <th>Actions</th> */}
        </tr>
      </thead>
    );
  };

  const handleDownloadButton = async (file) => {
    const token = getToken();
    // const fileExtension = file.split(".").pop();
    const payload = {
      filename: `paymentproof`,
      filepath: file,
    };
    await CreditqDefaulters.downloadPaymentProof(payload, token);
  };

  const _itemsList = () => {
    if (!payments?.data?.length) {
      return (
        <tr>
          <td colSpan="8">No Settlement Charge found!!</td>
        </tr>
      );
    }
    return payments.data.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{format(new Date(item.transaction_date), "yyyy-MM-dd")}</td>
          <td>{format(new Date(item.date_created), "yyyy-MM-dd")}</td>
          <td>{item.amount ? item.amount : ""}</td>
          <td>{item.settlement_type}</td>
          <td>
            {item.final_approval_status !== "pending"
              ? item.final_approval_status
              : item.l1_approval_status !== "PENDING"
              ? "Waiting for higher approval"
              : "pending"}
          </td>
          <td
            onClick={() => handleDownloadButton(item.evidence_filename)}
            style={{ cursor: "pointer", color: "#007bff" }}
          >
            {"Download"}
          </td>
          {/* <td className={Classes.actions}>
            <Link
              to={`/payment/${item.paid_to}/${defaulter_case_id}/edit/${item.id}`}
            >
              {item.status === "draft" || item.status === "pending_for_approval"
                ? "Edit"
                : "View"}
            </Link>
          </td> */}
        </tr>
      );
    });
  };

  return (
    <>
      <Table className="minimalistic-table">
        {_tableHeader()}
        <tbody>{_itemsList()}</tbody>
      </Table>
    </>
  );
};
export default SettlementPaymentHistory;
